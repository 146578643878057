<template>
  <!-- error message -->
  <div class="mb-4" :class="{'d-none': !status}">
    <div class="toast d-flex align-items-center text-white bg-danger border-0 fade show"
         role="alert" aria-live="assertive" aria-atomic="true"
         v-if="status && errors.length === 0">
      <div class="toast-body">
        <h6>{{ title }}</h6>
        <span>{{ details }}</span>
      </div>
      <button @click="$emit('hide')" type="button"
              class="btn-close btn-close-white ms-auto me-2"
              data-dismiss="toast"
              aria-label="Close">
      </button>
    </div>
    <ValidationErrorsList :isError="status && errors.length > 0" :error="title" :errors="errors" />
  </div>
  <!-- end error message -->
</template>

<script>

import ValidationErrorsList from "@/components/form/ValidationErrorsList";

export default {
  name: "ResponseError",
  components: {ValidationErrorsList},
  props: {
    title: String,
    details: String,
    errors: Array,
    status: Boolean,
  },
}
</script>
<style lang="scss" scoped>
.toast {
  max-width: 100%;
}
</style>
