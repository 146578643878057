<template>
  <div class="modal" id="loandecline">
    <div class="modal-bg" @click="$emit('close')"></div>

    <div class="modal-block w-50" v-if="loading === true">
      <div class="modal-body">
        <div class="modal-body-container border-0">
          <div class="text-center">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal-block w-50" v-if="loading === false">
      <div class="modal-header d-block">
        <div class="d-flex">
          <h3>
            Decline / Cancel
          </h3>
        </div>
      </div>
      <div class="modal-body">
        <div class="modal-body-container mt-2">
          <div class="modal-body-container_title">
            Borrower : {{ loan.borrower }}
          </div>
          <div class="row">
            <div class="col-md-12">
              <label for="fieldtype">
                Type
              </label>
              <multiselect
                v-model="formData.type"
                id="fieldtype"
                class="full-width"
                :options="loan.type"
                :searchable="true"
                :close-on-select="true"
                :allow-empty="false"
              >
              </multiselect>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-md-12 form-group full-width">
              <label for="fieldNote">
                Note
              </label>
              <textarea id="fieldNote"
                v-model="formData.note"
                rows="4"
                class="form-control"
              ></textarea>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-md-6">
              <button type="button" class="btn btn-primary w-50" @click="declineLoan">
                <span v-if="formData.loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                <span v-if="!formData.loading">Proceed</span>
              </button>
            </div>
            <div class="col-md-6">
              <div v-if="formData.isError" class="toast d-flex align-items-center text-white bg-danger border-0" :class="{'fade show': formData.isError}" role="alert" aria-live="assertive" aria-atomic="true">
                <div class="toast-body">
                  {{ response.message }}
                </div>
                <button @click="formData.isError = false" type="button" class="btn-close btn-close-white ms-auto me-2" data-dismiss="toast" aria-label="Close"></button>
              </div>
              <div v-if="formData.isSuccess" class="toast d-flex align-items-center text-white bg-success border-0" :class="{'fade show': formData.isSuccess}" role="alert" aria-live="assertive" aria-atomic="true">
                <div class="toast-body">
                  {{ response.message }}
                </div>
                <button @click="formData.isSuccess = false" type="button" class="btn-close btn-close-white ms-auto me-2" data-dismiss="toast" aria-label="Close"></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'

export default {
  name: "LoanDeclineModal",
  components: {Multiselect},
  props: {
    loanId: Number
  },
  data() {
    return {
      loading: true,
      loan: {
        type: ['Decline', 'Cancel'],
        borrower: 'Zarkar, Ashish',
      },
      response:{
        message: ''
      },
      formData: {
        isSuccess: false,
        isError: false,
        loading: false,
        type: 'Decline',
        note: ''
      }
    }
  },
  methods: {
    loadLoan() {
      this.loading = true
      this.$http.get(`/api/v1/loans/borrower/byloanid/${this.loanId}`)
        .then((res) => {
          this.loading = false
          this.loan.borrower = res.data.borrower
        })
    },
    declineLoan() {
      this.formData.loading = true
      let formData = new FormData()
      formData.append('type', this.formData.type)
      formData.append('note', this.formData.note)

      this.$http.post(`/api/v1/loans/decline/${this.loanId}`, formData)
        .then((res) => {
          if (res.data.status === 'success') {
            this.pushAlert('success', res.data.message)
            setTimeout(() => {
              this.$emit('load-loans')
              this.$emit('close')
            }, 1500)
          } else {
            this.pushAlert('error', res.data.message)
          }
        })
        .catch((err) => {
          this.pushAlert('error', err.response.data.error)
        })
        .finally(() => this.formData.loading = false)
    }
  },
  created() {
    this.loadLoan()
  }
}
</script>
<style lang="scss" scoped>
#loanApp {
  .status {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 28px;
    padding: 6px 12px;
    &.pending {
      border: 1px solid #E7E8E8;
      border-radius: 8px;
      background-color: #F6F6F6;
      color: #000000;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 18px;
      margin-left: 12px;
    }
  }
}
</style>
