<template>
  <div class="modal" id="loanApp">
    <div class="modal-bg" @click="$emit('close')"></div>

    <div class="modal-block">
      <div class="text-center mt-5" v-show="ui.loading">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
      <div class="mt-5" v-show="ui.isError">
        <div class="alert alert-danger">
          {{ui.error}}
        </div>
      </div>

      <div class="modal-header d-block" v-if="!ui.loading && !ui.isError">
        <div class="d-flex justify-content-between align-items-center">
          <h3>{{ loan.isReferral === 'Yes' ? 'Referral Loan Application' : 'Loan Application' }}</h3>
          <button class="btn btn-control" @click="$emit('editApplication')">
            <img src="@/assets/icons/icon-edit-normal.svg" alt="Edit">
          </button>
        </div>
      </div>
      <div class="modal-body" id="modal-body-scroll">
        <div v-show="!ui.loading">
          <div class="modal-body-container mb-3">
            <div class="row">
              <div class="col-1">
                <v-avatar :username="borrowerName" :size="42" rounded />
              </div>
              <div class="col-10 my-auto">
                <div class="d-flex align-items-center">
                <span>
                  {{ borrowerName }}
                </span>
                  <span class="status pending">
                  {{ status }}
                </span>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-body-container mt-5">
            <div class="modal-body-container_title">
              Borrower
            </div>
            <div class="table">
              <table class="table">
                <tbody>
                <tr v-for="(val, key) in borrower" :key="key">
                  <td>
                    {{key}}
                  </td>
                  <td>
                    {{val}}
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="modal-body-container">
            <div class="modal-body-container_title">
              Loan Information
            </div>
            <div class="table">
              <table class="table">
                <tbody>
                <tr v-for="(val, key) in loan" :key="key">
                  <td>
                    {{key}}
                  </td>
                  <td v-if="key === 'FeesForBorrower'" style="white-space: break-spaces;">
                    {{val}}
                  </td>
                  <td v-else>
                    {{val}}
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="modal-body-container border-0" v-if="stips.length">
          <div class="modal-body-container_title">
            Stips
          </div>
          <div class="attach-file" v-for="stip in stips" :key="stip.id">
            <div class="cursor-pointer" @click="openFilePreview('stip', stip.id, stip.clientFilename)">
              <img src="@/assets/icons/icon-attachment-18.svg" alt="Attach icon">
              <span class="file-name">{{stip.clientFilename}}</span>
            </div>
          </div>
        </div>
        <div class="modal-body-container border-0" v-if="referralDocs.length">
          <div class="modal-body-container_title">Referral docs</div>
          <div class="attach-file mt-2" v-for="stip in referralDocs" :key="stip.id">
            <div class="cursor-pointer" @click="openFilePreview('loan_referral', stip.id, stip.clientFilename)">
              <img src="@/assets/icons/icon-attachment-18.svg" alt="Attach icon">
              <span class="file-name">{{stip.clientFilename}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PerfectScrollbar from 'perfect-scrollbar'

export default {
  name: "LoanAppModal",
  props: {
    loanId: Number
  },
  data: () => {
    return {
      status: 'Pending',
      loan: {},
      borrower: {},
      stips: [],
      referralDocs: [],
      ui: {loading: false, isError: false, error: '', ps: null},
      borrowerName: ''
    }
  },
  created() {
    this.ui.loading = true
    this.$http.get('/api/v1/loans/view/' + this.loanId)
      .then((response) => {
        this.loan = response.data.loan
        this.borrower = response.data.borrower
        this.borrowerName = response.data.borrowerName
        this.status = response.data.status
        this.stips = response.data.stips
        this.referralDocs = response.data.referralDocs
        this.ui.isError = false
      }).catch((e) => {
      this.ui.isError = true
      this.ui.error = this.getFirstErrorFromResponse(e.response)
    }).finally(() => {
      this.ui.loading = false
    })
  },
  mounted() {
    const scrollbar = document.getElementById('modal-body-scroll');
    this.ui.ps = new PerfectScrollbar(scrollbar, {
      useBothWheelAxes: false,
      suppressScrollX: true
    });
  }
}
</script>
<style lang="scss" scoped>
#modal-body-scroll {
  position: relative;
  height: calc(100% - 50px);
  margin-bottom: 20px;
}
.table {
  overflow: initial;

  table {
    table-layout: fixed;
    white-space: initial!important;
    width: 100%;
    tbody {
      tr {
        min-height: 100%;
        max-height: 100%;
        td {
          min-height: 100%;
          max-height: 100%;
          width: fit-content;
          word-wrap: break-word;
          &:first-child {
            width: 40%;
          }
        }
      }
    }
  }
}
#loanApp {
  .status {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 28px;
    padding: 6px 12px;

    &.pending {
      border: 1px solid #E7E8E8;
      border-radius: 8px;
      background-color: #F6F6F6;
      color: #000000;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 18px;
      margin-left: 12px;
    }
  }
  table {
    tr {
      td {
        padding: 10px 12px 10px 10px;
        white-space: break-spaces;
      }
    }
  }
}
</style>
