<template>
  <div class="d-flex">
    <img src="@/assets/images/male.png" alt="Avatar" class="avatar">
    <div class="w-100 note-body">
      <div class="task-header d-flex justify-content-between">
        <span class="task-header-assigner">UWM Sync Update Fields</span>
        <span class="task-date">{{ formatDate(note.createdAt, 'MM/DD/YYYY hh:mm a') }}</span>
      </div>
      <span class="assigned-task">Application has been updated:</span>
      <div v-for="value in note.changes" :key="value.property"
            class="app-updated d-flex align-items-center">
        <div>{{ value.property }}</div>
        <div>
          <img alt="" src="@/assets/icons/icon-value-removed.svg">
          <span>{{ value.oldValue }}</span>
        </div>
        <div>
          <img alt="" src="@/assets/icons/icon-value-removed-icon-value-added.svg">
          <span>{{ value.newValue }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UwmNotes",
  props: {
    note: Object
  }
}
</script>

<style lang="scss" scoped>

.app-updated {
  border: 1px solid rgba(239, 239, 239, 1);
  border-radius: 4px;
  padding: 10px 12px;
  margin-top: 5px;

  div {
    width: 33%;

    &:first-child,
    &:last-child {
      color: rgba(0, 0, 0, 1);
      font-size: 11px;
      letter-spacing: 0;
      line-height: 17px;
    }

    &:nth-child(2) {
      span {
        opacity: 0.46;
        color: rgba(0, 0, 0, 1);
        font-size: 11px;
        letter-spacing: 0;
        line-height: 17px;
      }
    }
  }
}

.avatar {
  height: 36px;
  width: 36px;
  border: 1px solid rgba(237, 237, 238, 1);
  border-radius: 50%;
}

.note-body {
  padding: 0 12px 20px 12px;
  border-bottom: 1px solid rgba(231, 232, 232, 1);
}

.task-header-assigner {
  color: rgba(0, 0, 0, 1);
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
}

.task-date {
  opacity: 0.46;
  color: rgba(0, 0, 0, 1);
  font-size: 10px;
  letter-spacing: 0;
  line-height: 16px;
}

.assigned-task {
  white-space: break-spaces;
  color: rgba(0, 0, 0, 1);
  font-size: 12px;
  font-style: italic;
  font-weight: 300;
  letter-spacing: 0;
}

.task-body {
  border: 1px solid rgba(239, 239, 239, 1);
  border-radius: 4px;
  padding: 9px;
  min-height: 60px;
  margin-top: 9px;
}

.task-info-name {
  color: rgba(0, 0, 0, 1);
  font-size: 13px;
  letter-spacing: 0;
  line-height: 20px;
}

.task-comments img {
  opacity: 0.46;
}

.task-comments span {
  color: rgba(138, 138, 138, 1);
  font-size: 11px;
  letter-spacing: 0;
  line-height: 11px;
}

.update-task {
  color: rgba(0, 0, 0, 1);
  font-size: 13px;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: 7px;
}

.bg-primary {
  height: 36px;
  max-width: 180px;
  width: 100%;
}

.notes-field {
  button {
    min-width: 220px;
    color: #fff;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 14px;

    background-color: #0d6efd !important;
  }
}
</style>
