<template>
  <div>
    <div class="modal modal-center">
      <div class="modal-bg" @click="$emit('close')"></div>
      <div class="modal-block">
        <div class="text-center my-5" v-if="ui.loading === true">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        <div class="modal-body" v-if="ui.loading === false">
          <div class="title">
            <button class="btn btn-control close-btn" @click="$emit('close')">
              <img src="@/assets/icons/icon-close.png" alt="">
            </button>
            <span class="loan-file">Loan File</span>
            <p>
              {{ loan.borrowerName }}
              <span class="status">
              {{ loan.status }}
            </span>
            </p>
          </div>
          <div class="modal-body-container mb-0 pb-0 pt-3">
            <ResponseError
              :status="error.status"
              :title="error.title"
              :details="error.details"
              :errors="error.errors"
            />
            <div class="row">
              <div class="col-3 details">
                <span>Loan Amount</span>
                <p>${{ loan.loanAmount }}</p>
              </div>
              <div class="col-3 details">
                <span>Rate</span>
                <p>{{ loan.rate }}%</p>
              </div>
              <div class="col-3 details">
                <span>Date Submitted</span>
                <p>{{ loan.createdAt }}</p>
              </div>
              <div class="col-3 details">
                <span>In Process</span>
                <p>{{ (loan.inProgress) ? loan.inProgress : 'N/A' }}</p>
              </div>
            </div>
          </div>
          <div class="row modal-body-container mb-0 py-3">
            <div class="col-4">
              <div class="d-flex align-items-center">
                <button class="btn bg-white" @click="modals.documents = true">
                  View Documents
                </button>
                <img v-if="loan.docsRequested > 0 && loan.docsRequested <= loan.docsReceived"
                     src="@/assets/icons/Received.svg"
                     class="ms-2" alt=""
                     content="Docs Received"
                     v-tippy="{ placement : 'right', arrow : true, arrowType : 'round' }">
                <div class="received-docs" v-else-if="loan.docsReceived > 0"
                     content="New Docs"
                     v-tippy="{ placement : 'right', arrow : true, arrowType : 'round' }">
                  {{loan.docsReceived}}
                </div>
                <img v-else-if="loan.docsRequested > 0 && loan.docsRequested > loan.docsReceived"
                     content="Docs Pending"
                     v-tippy="{ placement : 'right', arrow : true, arrowType : 'round' }"
                     src="@/assets/icons/Pending.svg"
                     class="ms-2" alt="">
                <img v-else-if="loan.docsRequested === 0"
                     content="No Docs"
                     v-tippy="{ placement : 'right', arrow : true, arrowType : 'round' }"
                     src="@/assets/icons/No Requested Docs.svg"
                     class="ms-2" alt="">
              </div>
            </div>
            <div class="col-4">
              <div class="d-flex align-items-center">
                <button class="btn bg-white" @click="modals.loanApp = true">
                  View Application
                </button>
                <button class="btn btn-control ms-2"
                        @click="modals.editOrigination = true"
                        content="Edit Application"
                        v-tippy="{ placement : 'bottom', arrow : true, arrowType : 'round' }">
                  <img src="@/assets/icons/icon-edit-normal.svg" alt="">
                </button>
                <button class="btn btn-control ms-2"
                        content="Show Notes"
                        @click="modals.note = true"
                        v-tippy="{ placement : 'bottom', arrow : true, theme : 'dark', arrowType : 'round' }">
                  <img src="@/assets/icons/icon-notes.svg" alt="">
                </button>
              </div>
            </div>
            <div class="col-4">
              <button :name="'borrowerAction_'+loanId"
                      class="btn btn-control ms-auto" parent >
                <img src="@/assets/icons/icon-more.svg" alt="">
              </button>
              <tippy :to="'borrowerAction_'+loanId"
                     trigger="click"
                     arrowType="round"
                     boundary="window"
                     interactive="true"
                     animateFill="false"
                     placement="bottom-end"
                     zIndex="9999" hideOnClick="true" theme="light"
                     sticky="false" class="borrower-action"
                     v-if="!modals.action">
                <div class="borrower-action-block">
                  <ul>
                    <li>
                      <a href="#">
                        <img src="@/assets/icons/icon-login.svg" alt="">
                        Login to Lender Account
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img src="@/assets/icons/icon-email.svg" alt="">
                        Email to Rep
                      </a>
                    </li>
                    <li class="action-label">
                      Process
                    </li>
                    <li>
                      <a href="#">
                        <img src="@/assets/icons/icon-appraisal.svg" alt="">
                        Request Appraisal
                      </a>
                    </li>
                    <li @click="modals.escrowDetails = true, modals.action=true">
                      <a href="#">
                        <img src="@/assets/icons/icon-escrow.svg" alt="">
                        Escrow
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img src="@/assets/icons/icon-underwriting.svg" alt="">
                        Underwriting
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img src="@/assets/icons/icon-closing.svg" alt="">
                        Closing
                      </a>
                    </li>
                    <li class="action-label">
                      Actions
                    </li>
                    <li>
                      <a href="#">
                        <img src="@/assets/icons/icon-moveto.svg" alt="">
                        Move to Funding Request
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img src="@/assets/icons/icon-sync.svg" alt="">
                        Sync
                      </a>
                    </li>
                    <li v-if="isGranted('ROLE_CEO') || isGranted('ROLE_CEO_ASSISTANT') || isGranted('ROLE_BRANCH_MANAGER')">
                      <a href="#" @click="modals.loanReassign = true, modals.action=true">
                        <img src="@/assets/icons/icon-profile.svg" alt="">
                        Reassign Loan
                      </a>
                    </li>
                    <li>
                      <a href="#" @click="modals.loanDecline = true, modals.action=true">
                        <img src="@/assets/icons/icon-archive.svg" alt="">
                        Decline
                      </a>
                    </li>
                  </ul>
                </div>
              </tippy>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-6 details">
              <span>Agent</span>
              <p>{{ loan.agent.name }}</p>
            </div>
            <div class="col-6 details">
              <span>Lender</span>
              <p>{{ loan.lender }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <DocumentsModal
      @close="modals.documents = false, modals.action=false"
      :loanId="loanId"
      @docsRequested="loadingForm"
      v-if="modals.documents"></DocumentsModal>

    <LoanAppModal
      :loan-id="loanId"
      @close="modals.loanApp = false, modals.action=false"
      v-if="modals.loanApp"></LoanAppModal>

    <EditOriginationModal
      :loanId="loanId"
      @close="modals.editOrigination = false, modals.action=false"
      @loanUpdated="loadingForm"
      v-if="modals.editOrigination"></EditOriginationModal>

    <NotesModal
      :loanId="loanId"
      @close="modals.note = false, modals.action=false"
      v-if="modals.note"
      @openTask="openTaskModal($event)"></NotesModal>

    <EscrowModal
      :loanId="loanId"
      @editLoan="modals.editLoan=true"
      @close="modals.escrowDetails = false, modals.action=false"
      v-if="modals.escrowDetails"></EscrowModal>

    <LoanReassignModal
      :loanId="loanId"
      @close="modals.loanReassign = false, modals.action=false"
      @load-loans="loadingForm"
      v-if="modals.loanReassign"></LoanReassignModal>

    <LoanDeclineModal
      :loanId="loanId"
      @close="modals.loanDecline = false, modals.action=false"
      @load-loans="loadingForm"
      v-if="modals.loanDecline"></LoanDeclineModal>
  </div>
</template>

<script>

import DocumentsModal from "@/views/loans/DocumentsModal";
import LoanAppModal from "@/views/loans/LoanAppModal";
import EditOriginationModal from "@/views/loans/EditOriginationModal";
import NotesModal from "@/views/loans/NotesModal";
import EscrowModal from "@/views/loans/escrow/EscrowModal";
import LoanReassignModal from "@/views/loans/LoanReassignModal";
import LoanDeclineModal from "@/views/loans/LoanDeclineModal";
import ResponseError from "@/views/tasks/includes/ResponseError";

export default {
  name: "LoanFile",
  components: {DocumentsModal, LoanAppModal, EditOriginationModal, NotesModal,
    EscrowModal, LoanReassignModal, LoanDeclineModal, ResponseError},
  props: {
    loanId: Number
  },
  data() {
    return {
      ui: {
        loading: false,
      },
      loan: {},
      modals: {
        documents: false,
        loanApp: false,
        editOrigination: false,
        note: false,
        escrowDetails: false,
        loanReassign: false,
        loanDecline: false,
        editLoan: false,
        action: false
      },
      error: {
        status: false,
        title: '',
        details: '',
        errors: []
      }
    }
  },
  methods: {
    loadingForm() {
      this.ui.loading = true
      this.$http
        .get(`/api/v1/loans/show/${this.loanId}`)
        .then((res) => {
          this.loan = res.data
        })
        .catch((err) => {
          this.error.status = true
          if (err.response.data.errors) {
            this.error.title = err.response.data.message.trim()
            this.error.errors = err.response.data.errors
          } else if (err.response.data.status === 500) {
            this.hideError()
            this.$store.dispatch('OPEN_STATUS_NOTIFICATION', this.statusNotification = {
              msg: 'Internal Server Error.',
              status: 'error',
              delay: 2000
            });
          } else {
            this.error.title = err.response.data.title.trim()
            this.error.details = err.response.data.detail.trim()
          }
        })
        .finally(() => {
          this.ui.loading = false
        })
    },
    hideError() {
      this.error = {
        status: false,
        title: '',
        details: '',
        errors: []
      }
    },
  },
  beforeMount() {
    this.loadingForm()
  }
}
</script>

<style lang="scss" scoped>
.modal.modal-center {
  .modal-block {
    height: auto;
    padding: 20px;
    margin-top: 10vh;
    overflow-y: auto;
    border-radius: 8px;
  }
  .modal-body {
    padding: 30px;
  }
  .title {
    color: #000000;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 27px;
    .loan-file {
      opacity: 0.46;
      color: #000000;
      font-size: 11px;
      letter-spacing: 0;
      line-height: 17px;
    }
    .status {
      box-sizing: border-box;
      border: 1px solid #0076FF;
      border-radius: 12px;
      background-color: #FFFFFF;
      color: #0078FF;
      font-size: 11px;
      letter-spacing: 0;
      line-height: 11px;
      padding: .4rem;
      margin-left: 1rem;
    }
  }
  .close-btn {
    float: right;
    &:hover {
      background-color: #FFF;
    }
  }
  .details {
    span {
      opacity: 0.46;
      color: #000000;
      font-size: 11px;
      letter-spacing: 0;
      line-height: 17px;
    }
    p {
      color: #000000;
      font-size: 13px;
      letter-spacing: 0;
      line-height: 20px;
    }
  }
}
.borrower-action-block {
  background: white!important;
  ul {
    padding: 12px 0;
    list-style: none;
    li {
      text-align: initial;
      padding: 4px 11px;
      &:hover {
        background-color: rgba(0,0,0,.05);
      }
      a {
        color: rgba(0,0,0,1);
        font-size: 12px;
        letter-spacing: 0;
        line-height: 18px;
        text-decoration: none;
        img {
          opacity: 0.46;
        }
      }
    }
  }
  .action-label {
    opacity: 0.5;
    color: rgba(0,0,0,1);
    font-size: 11px;
    letter-spacing: 0;
    line-height: 17px;
    margin-top: 8px;
    margin-bottom: 8px;
  }
}
</style>
