<template>
  <div class="modal" id="documents">
    <div class="modal-bg" @click="$emit('close')"></div>

    <div class="modal-block">
      <div class="text-center mt-5" v-show="ui.loading === true">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>

      <div class="modal-header" v-show="ui.loading === false">
        <div class="">
          <h3>
            Documents
          </h3>
        </div>
      </div>
      <div class="modal-body" v-show="ui.loading === false">
        <div id="modal-body-scroll">
          <div class="modal-body-container mb-3">
            <div class="row">
              <div class="col-1">
                <v-avatar :username="borrower.name" :size="42" rounded />
              </div>
              <div class="col-10 my-auto">
              <span>
                {{ borrower.name }}
              </span>
                <span class="color-light-grey ps-2">
                {{ borrower.email }}
              </span>
              </div>
            </div>
          </div>
          <div class="page-tabs d-flex justify-content-between p-0">
            <ul class="tab-list p-0">
              <li :class="{ active: tab === 'Received' }" @click="setTab('Received')">
                Received ({{docs.received.length}})
              </li>
              <li :class="{ active: tab === 'Request' }" @click="setTab('Request')">
                Request Documents ({{docs.requested.length}})
              </li>
              <li :class="{ active: tab === 'Stips' }" @click="setTab('Stips')">
                Stips ({{stips.length}})
              </li>
              <li :class="{ active: tab === 'E-Sign' }" @click="setTab('E-Sign')">
                E-Sign <span class="d-inline-block ms-1" v-if="esign.status !== 'pending' && esign.status !== 'declined'">({{esign.docs.length}})</span>
                <span class="badge badge-primary" v-if="esign.status === 'pending'">{{ esign.status }}</span>
                <span class="badge badge-danger" v-if="esign.status === 'declined'">{{ esign.status }}</span>
              </li>
            </ul>
          </div>
          <div class="modal-body-tab" :class="{ 'd-block': tab === 'Received', 'd-none': tab !== 'Received'}">
            <div class="modal-body-container border-0 mt-5">
              <div class="modal-body-container_title">Received</div>
              <div>
                <div class="d-flex justify-content-between align-items-center mt-2 mb-2" v-if="docs.received.length">
                  <div class="modal-body-container_subtitle">
                    {{docs.received[0].fullDate}}
                  </div>
                  <button class="btn-link btn-link-blue" @click="downloadAllDocs">
                    Download ({{docs.received.length}})
                  </button>
                </div>
                <div v-for="receive in docs.received" :key="receive.id">
                  <div class="attach-file mb-2">
                    <div class="">
                      <img src="@/assets/icons/icon-attachment-18.svg" alt="Attach icon">
                      <span class="file-name" :content="receive.clientFilename" v-tippy>
                      {{ receive.type ? receive.type.title : receive.clientFilename }}
                    </span>
                    </div>
                    <div class="file-controls d-flex">
                      <button class="btn btn-control" @click="assignType = receive.id" content="Assign document type" v-tippy>
                        <img src="@/assets/icons/assign-blue.svg" alt="">
                      </button>
                      <button class="btn btn-control" @click="openFilePreview('loan_doc', receive.id, receive.clientFilename)">
                        <img src="@/assets/icons/icon-download.svg" alt="">
                      </button>
                      <button class="btn btn-control" @click="deleteReceivedDoc(receive.id)">
                        <img src="@/assets/icons/icon-delete-normal.svg" alt="">
                      </button>
                    </div>
                  </div>
                  <div class="form-group mb-2 full-width" v-if="assignType === receive.id">
                    <multiselect v-model="receive.type"
                                 :placeholder="receive.clientFilename"
                                 :options="types"
                                 track-by="id"
                                 label="title"
                                 @input="assignSelectedType(receive.id)"
                                 class="full-width"
                                 :searchable="true"
                                 :close-on-select="true"
                                 :allow-empty="false"
                                 :show-labels="false">
                    </multiselect>
                  </div>
                </div>
                <div class="col-12 modal-alert modal-alert_advice text-center" v-if="!docs.received.length">
                  There's no received documents.
                </div>
              </div>
            </div>
          </div>
          <div class="modal-body-tab" :class="{ 'd-block': tab === 'Request', 'd-none': tab !== 'Request'}">
            <div class="modal-body-container mt-5" v-if="docs.requested.length">
              <div class="modal-body-container_title">
                Pending
              </div>
              <div v-for="doc in docs.requested" :key="doc.name" class="d-flex justify-content-between align-items-center mt-3">
              <span class="modal-body-container_notice">
                {{ doc.title }}
              </span>
                <span class="modal-body-container_notice-date">
                {{ doc.date }} ago
              </span>
              </div>
            </div>
            <div class="modal-body-container border-0 mt-5">
              <div class="modal-body-container_title">Request Documents</div>
              <div class="form-group mb-3">
                <input type="email" v-model="email" class="form-control" placeholder="Borrower Email">
              </div>
              <div class="documents-list">
                <div class="document-item"
                     v-for="doc in types" :key="doc.id">
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" v-model="requestDocs[doc.id]" :id="'docType' + doc.id">
                    <label :for="'docType' + doc.id" class="document-item-title mb-0 d-flex justify-content-between align-items-center">
                      <div>
                        {{ doc.title }}
                        <img v-if="doc.description" class="ms-1" src="@/assets/icons/icon-info.svg"
                             :alt="doc.description" v-tippy :content="doc.description">
                      </div>
                      <button class="btn btn-control" v-if="doc.isGranted.delete" content="Delete"
                              v-tippy="{arrow: false, placement: 'bottom'}" @click="deleteDoc(doc.id)">
                        <img class="ms-1 cursor-pointer" src="@/assets/icons/icon-delete.svg" alt="Delete">
                      </button>
                      <span class="document-item-received" v-if="doc.isReceived">Received</span>
                      <span class="document-item-received" v-else-if="doc.isRequested">Pending</span>
                    </label>
                  </div>
                </div>
                <div class="custom-docs" v-if="ui.customDoc === true">
                  <div class="alert alert-danger" v-if="formData.customDocs.isError">
                    {{ formData.customDocs.error }}
                  </div>
                  <div class="form-group full-width">
                    <label for="fieldCustomDocName">Name Document</label>
                    <input type="text" id="fieldCustomDocName" v-model="formData.customDocs.name" class="form-control">
                  </div>
                  <div class="form-group full-width mt-3">
                    <label for="fieldDecription">Description</label>
                    <textarea class="form-control" id="fieldDecription" v-model="formData.customDocs.description"
                              rows="2" placeholder="(Optional) Will be shown to borrower in email"></textarea>
                  </div>
                </div>
                <div class="d-flex align-items-center add-custom-document" @click="ui.customDoc = !ui.customDoc" v-if="ui.customDoc === false">
                  <img src="@/assets/icons/bold-icon-sm add blue.svg" alt="">
                  <span>Add Custom Document</span>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-body-tab" :class="{ 'd-block': tab === 'Stips', 'd-none': tab !== 'Stips'}">
            <div class="modal-body-container border-0 mt-5">
              <div class="modal-body-container_title">Stips</div>
              <div>
                <div class="attach-file my-2" v-for="stip in stips" :key="stip.id">
                  <div class="cursor-pointer" :content="stip.clientFilename" v-tippy>
                    <img src="@/assets/icons/icon-attachment-18.svg" alt="Attach icon">
                    <span class="file-name">{{stip.clientFilename}}</span>
                  </div>
                  <div class="file-controls d-flex">
                    <button class="btn btn-control" @click="openFilePreview('stip', stip.id, stip.clientFilename)">
                      <img src="@/assets/icons/icon-preview.svg" alt="">
                    </button>
                  </div>
                </div>

                <div class="col-12 modal-alert modal-alert_advice text-center" v-if="!stips.length">
                  There's no documents.
                </div>
              </div>
            </div>
          </div>
          <div class="modal-body-tab" :class="{ 'd-block': tab === 'E-Sign', 'd-none': tab !== 'E-Sign'}">
            <div class="modal-body-container border-0 mt-5">
              <div class="modal-body-container_title d-flex justify-content-between align-items-center">
                E-Sign
                <base-button title="Remind" action="secondary-default" v-if="esign.status === 'pending'"
                             @click-btn="remindESign" :loading="ui.reminding"  />
              </div>
              <div v-if="esign.status === 'signed'">
                <div class="attach-file my-2" v-for="(edoc, index) in esign.docs" :key="index">
                  <div class="cursor-pointer" :content="edoc.name" v-tippy>
                    <img src="@/assets/icons/icon-attachment-18.svg" alt="Attach icon">
                    <span class="file-name">{{edoc.name}}</span>
                  </div>
                  <div class="file-controls d-flex">
                    <button class="btn btn-control" @click="openFilePreview('esign', esign.id, edoc.filename)">
                      <img src="@/assets/icons/icon-preview.svg" alt="">
                    </button>
                  </div>
                </div>

                <div class="col-12 modal-alert modal-alert_advice text-center" v-if="!esign.docs.length">
                  There's no documents.
                </div>
              </div>
              <div class="col-12 modal-alert modal-alert_advice text-center" v-else-if="esign.status === 'pending'">
                Documents are pending.
              </div>
              <div class="col-12 modal-alert modal-alert_advice text-center" v-else-if="esign.status === 'declined'">
                Documents was declined.
              </div>
              <div class="col-12 modal-alert modal-alert_advice text-center" v-else-if="esign.status === null">
                TThere's no documents.
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal-footer d-flex justify-content-start" v-if="ui.loading === false">
        <button class="btn bg-primary submit" v-if="tab === 'Received' && ui.customDoc === false" @click="downloadAllDocs" :class="{disabled: !docs.received.length}">
          Download All
        </button>
        <button class="btn bg-primary submit" v-if="tab === 'Stips' && ui.customDoc === false" @click="downloadAllStips" :class="{disabled: !stips.length}">
          Download All
        </button>
        <button class="btn bg-primary submit" v-if="tab === 'Request' && ui.customDoc === false" @click="submitForm">
          <span v-if="formData.requestDocs.success">Email Sent</span>
          <div v-else-if="formData.requestDocs.loading === true">
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          </div>
          <span v-else>Request Documents</span>
        </button>
        <button class="btn bg-primary submit" v-if="ui.customDoc === true" @click="addCustomDoc">
          <div v-if="formData.customDocs.loading === true">
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          </div>
          <span v-if="formData.customDocs.loading === false">
            Add Custom Document
          </span>
        </button>
        <button v-if="ui.customDoc === true" class="btn btn-cancel" @click="ui.customDoc = false"> Discard</button>
      </div>
    </div>
  </div>
</template>

<script>
import {getBackendUrl} from "../../utils/backendUrl";
import Multiselect from 'vue-multiselect'
import PerfectScrollbar from 'perfect-scrollbar'
import BaseButton from "../../components/BaseButton.vue";

export default {
  name: "DocumentsModal",
  components: {BaseButton, Multiselect},
  props: {
    loanId: Number,
  },
  data() {
    return {
      tab: 'Received',
      searchField: '',
      ui: {
        loading: false,
        customDoc: false,
        customDocError: '',
        ps: null,
        reminding: false,
      },
      types: [],
      requestDocs: {},
      docs: {received: [], requested: []},
      borrower: {},
      email: '',
      formData: {
        customDocs: {
          name: '',
          description: '',
          loading: false,
          isError: false,
          error: '',
        },
        requestDocs: {loading: false, success: false}
      },
      assignType: 0,
      stips: [],
      esign: {},
    }
  },
  methods: {
    setTab(tabName) {
      this.tab = tabName;
    },
    Documents() {
      this.ui.loading = true;
      this.$http.get(`/api/v1/loans/documents/view/${this.loanId}`).then((response) => {
        this.docs = response.data.docs
        this.stips = response.data.stips
        this.esign = response.data.esign
        this.borrower = response.data.borrower
        this.email = this.borrower.email
        this.types = response.data.types
        for (let doc of this.types) {
          this.requestDocs[doc.id] = !!doc.isPreselected
        }

        if (!this.docs.received.length) {
          this.setTab('Request');
        }

      }).catch(() => {
        // handle error
      }).finally(() => {
        this.ui.loading = false
      })
    },
    addCustomDoc() {
      this.formData.customDocs.loading = true;
      this.formData.customDocs.isError = false;

      this.$http.post('/api/v1/loans/documents/types/new', {
        title: this.formData.customDocs.name,
        description: this.formData.customDocs.description,
      }).then(() => {
        this.formData.customDocs.loading = false;
        this.ui.customDoc = false;
        this.Documents()
      }).catch((e) => {
        this.formData.customDocs.isError = true
        this.formData.customDocs.error = this.getFirstErrorFromResponse(e.response)
      })
    },
    deleteDoc(id) {
      if (confirm('Are you sure?')) {
        this.types = this.types.filter(doc => doc.id !== id);
        this.$http.delete('/api/v1/loans/documents/types/delete/' + id)
      }
    },
    deleteReceivedDoc(id) {
      if (confirm('Are you sure?')) {
        this.docs.received = this.docs.received.filter(doc => doc.id !== id);
        this.$http.delete('/api/v1/loans/documents/delete/' + id)
      }
    },
    submitForm() {
      this.formData.requestDocs.loading = true
      let docsIds = [];
      for (let k in this.requestDocs) {
        if (this.requestDocs[k] === true) {
          docsIds.push(Number.parseInt(k))
        }
      }
      this.$http.post('/api/v1/loans/documents/request/' + this.loanId, {
        email: this.email,
        docs: docsIds
      }).then(() => {
        this.$store.dispatch('OPEN_STATUS_NOTIFICATION', this.statusNotification = {
          msg: 'Documents requested',
          status: 'success',
          delay: 2000
        });
        this.Documents()
      })
        .catch((e) => {
          alert(this.getFirstErrorFromResponse(e.response));
        })
        .finally(() => {
          this.formData.requestDocs.loading = false
        })
    },
    downloadAllDocs() {
      window.location = getBackendUrl() + '/mlo/loans/documents/download-all/' + this.loanId
    },
    downloadAllStips() {
      window.location = getBackendUrl() + '/mlo/loan/submission/stip/download-all/'+ this.loanId
    },
    assignSelectedType(docId) {
      let doc = this.docs.received.filter((el) => el.id === docId)[0]
      this.assignType = 0;
      this.$http.post('/api/v1/loans/documents/assign-type/' + docId, {type: doc.type.id})
    },
    remindESign() {
      this.ui.reminding = true
      this.$http.post(`/api/v1/e-sign/${this.loanId}/remind`).then(() => {
        this.pushAlert('success', 'Remind was sent successfully.')
        this.ui.reminding = false
      }).catch(() => {
        this.pushAlert('error', 'Something went wrong.')
        this.ui.reminding = false
      })
    }
  },
  created() {
    this.Documents()
  },
  mounted() {
    const scrollbar = document.getElementById('modal-body-scroll');
    this.ui.ps = new PerfectScrollbar(scrollbar, {
      useBothWheelAxes: false,
      suppressScrollX: true
    });
  }
}
</script>

<style lang="scss" scoped>

.modal-body-container_notice,
.document-item-title{
  opacity: 0.7;
  color: #000000;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
}
.document-item-title {
  margin-left: 18px;
  width: 100%;
}
.modal-body-container_notice-date,
.document-item-received {
  opacity: 0.46;
  color: #000000;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 18px;
}

.document-item input {
  width: 18px;
  height: 18px;
  max-width: 18px;

  &:checked {
    width: 18px;
    height: 18px;
    max-width: 18px;
  }
}

.document-item {
  padding-bottom: 18px;
  margin-bottom: 18px;
  border-bottom: 1px solid #E7E8E8;
}

.add-custom-document {
  cursor: pointer;
}

.add-custom-document span {
  color: #266FE5;
  font-size: 15px;
  letter-spacing: 0;
  line-height: 23px;
  margin-left: 15px;
}

.file-upload {
  line-height: 30px;
}

.badge-primary {
  color: #fff;
  background-color: #007bff;
  margin-left: 10px;
  font-size: 0.85em;
}

.badge-danger {
  color: #fff;
  background-color: #dc3545;
  margin-left: 10px;
  font-size: 0.85em;
}

.badge {
  text-transform: capitalize;
}
</style>
