<template>
  <div id="notes" class="modal">
    <div class="modal-bg" @click="$emit('close')"></div>
    <div class="modal-block">
      <div v-if="!tasksLoading" class="modal-header">
        <div class="d-flex align-items-center">
          <h3 class="m-0">Notes</h3>
          <span class="sub-title ms-2">({{ loan.borrowerName }})</span>
        </div>
      </div>
      <div v-else class="text-center mt-5">
        <div class="spinner-border mt-5" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
      <div v-show="tasksLoading === false" id="modal-body-scroll" class="modal-body">
        <div class="modal-body-container mb-1">
          <div class="row">
            <div class="col-1">
              <v-avatar :size="42" :username="loan.borrowerName" rounded/>
            </div>
            <div class="col-10 my-auto">
              <div class="d-flex align-items-center">
                <span>
                  {{ loan.borrowerName }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-body-container border-0">
          <div :class="{ 'd-block': tab !== 'addTask', 'd-none': tab === 'addTask'}"
               class="page-tabs d-flex align-items-center justify-content-between mt-2 p-0">
            <ul class="tab-list p-0">
              <li :class="{ active: tab === 'Notes' }" @click="setTab('Notes')">Notes</li>
              <li :class="{ active: tab === 'Attachments' }" @click="setTab('Attachments')">Attachments</li>
              <li :class="{ active: tab === 'Todo' }" @click="setTab('Todo')">To Do ({{ taskTodoCount }})</li>
            </ul>
            <button class="btn btn-link btn-link-sm" @click="setTab('addTask')">Add Task</button>
          </div>
          <div class="notes-field" v-if="tab === 'Notes'">
            <div class="form-group col-md-12 mt-2 full-width">
          <textarea id="fieldComment"
                    v-model="comment"
                    class="form-control"
                    cols="30"
                    name=""
                    placeholder="Write a comment"
                    rows="4">
            </textarea>
            </div>
            <div class="d-flex justify-content-end mt-4" >
              <button :disabled="comment.trim() === '' || ui.disableButton" class="btn bg-primary submit"
                      @click="addComment">
                Add Note
              </button>
            </div>
          </div>
          <div :class="[ tab === 'Notes' ? 'd-block' : 'd-none']" class="modal-body-tab">
            <div v-for="note in notes" :key="note.id" class="note">
              <div v-if="note.type === 'note'" class="note mt-4">
                <div class="d-flex">
                  <img :src="agentPhoto(note.agent.photo)" alt="Avatar" class="avatar">
                  <div class="w-100 note-body">
                    <div class="task-header d-flex justify-content-between">
                      <span class="task-header-assigner">{{ note.agent.fullName }}</span>
                      <span class="task-date">{{ formatDate(note.createdAt, 'MM/DD/YYYY hh:mm a') }}</span>
                    </div>
                    <div class="task-body">
                      <div class="d-flex" style="white-space: pre-line;">
                        {{ note.message }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="note.type === 'uwm_note'" class="note mt-4">
                <div class="d-flex">
                  <img src="@/assets/images/male.png" alt="Avatar" class="avatar">
                  <div class="w-100 note-body">
                    <div class="task-header d-flex justify-content-between">
                      <span class="task-header-assigner">UWM Sync Note</span>
                      <span class="task-date">{{ formatDate(note.createdAt, 'MM/DD/YYYY hh:mm a') }}</span>
                    </div>
                    <div class="task-body">
                      <div class="d-flex" style="white-space: pre-line;">
                        {{ note.message }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="note.type === 'task'" class="d-flex note-click mt-4">
                <img :src="agentPhoto(note.task.assignedBy.photo)" alt="Avatar" class="avatar">
                <div class="w-100 note-body">
                  <div class="task-header d-flex justify-content-between">
                    <span class="task-header-assigner">{{ note.task.assignedBy.fullName }}</span>
                    <span class="task-date">{{ note.task.createdAtForHumans }}</span>
                  </div>
                  <span class="assigned-task">Assigned task</span>
                  <div class="task-body">
                    <div class="d-flex">
                      <div class="form-group form-check-round me-2" @click="toggleTaskModal(note.task.id, 'subTask')">
                        <input :checked="note.task.status === 'done'" class="form-check-input" type="checkbox" disabled >
                      </div>
                      <div class="task-info">
                        <div class="task-info-name" @click="toggleTaskModal(note.task.id, 'subTask')">{{ note.task.title }}</div>
                        <div class="d-flex mt-1">
                          <div class="task-comments" @click="toggleTaskModal(note.task.id, 'Comments')">
                            <img alt="Comments icon" src="@/assets/icons/icon-comment-18.svg">
                            <span class="ms-2">{{ note.task.comments.length }}</span>
                          </div>
                          <div class="task-comments ms-3" @click="toggleTaskModal(note.task.id, 'Attachments')">
                            <img alt="Attachments icon" src="@/assets/icons/icon-attachment-18.svg">
                            <span class="ms-2">{{ note.task.documetsTotal }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="note.type === 'edit'" class="note mt-4">
                <div class="d-flex">
                  <img :src="agentPhoto(note.agent.photo)" alt="Avatar" class="avatar">
                  <div class="w-100 note-body">
                    <div class="task-header d-flex justify-content-between">
                      <span class="task-header-assigner">{{ note.agent.fullName }}</span>
                      <span class="task-date">{{ formatDate(note.createdAt, 'MM/DD/YYYY hh:mm a') }}</span>
                    </div>
                    <span class="assigned-task">Application has been updated:</span>
                    <div v-for="value in note.changes" :key="value.property"
                         class="app-updated d-flex align-items-center">
                      <div>{{ value.property }}</div>
                      <div>
                        <img alt="" src="@/assets/icons/icon-value-removed.svg">
                        <span>{{ value.oldValue }}</span>
                      </div>
                      <div>
                        <img alt="" src="@/assets/icons/icon-value-removed-icon-value-added.svg">
                        <span>{{ value.newValue }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="note.type === 'uwm_sync'" class="note mt-4">
                <uwm-notes :note="note" />
              </div>
            </div>
          </div>
          <div :class="[ tab === 'Attachments' ? 'd-block' : 'd-none']" class="modal-body-tab">
            <div v-for="(item, index) in attachments" :key="index" class="note">
              <div class="note mt-4">
                <div class="d-flex">
                  <img :src="agentPhoto(item.author.photo)" alt="Avatar" class="avatar">
                  <div class="w-100 note-body">
                    <div class="task-header d-flex justify-content-between">
                      <span class="task-header-assigner">{{ item.author.fullName }}</span>
                      <span class="task-date">{{ item.createdAtForHumans }}</span>
                    </div>
                    <span class="update-task">{{ item.text }}</span>
                    <div v-for="(doc, index) in item.documents" :key="index" class="attach-file">
                      <div class="">
                        <img alt="Attach icon" src="@/assets/icons/icon-attachment-18.svg">
                        <span class="file-name">{{ doc.clientFilename }}</span>
                      </div>
                      <button @click="downloadDocument(doc.id, doc.clientFilename)">
                        <img alt="Delete" src="@/assets/icons/icon-download.svg">
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div :class="[ tab === 'Todo' ? 'd-block' : 'd-none']" class="modal-body-tab">
            <div v-for="(task, index) in tasks" :key="index" class="note">
              <div class="note note-click mt-4" @click="$emit('openTask', task.id)">
                <div class="d-flex">
                  <img :src="agentPhoto(task.assignedBy.photo)" alt="Avatar" class="avatar">
                  <div class="w-100 note-body">
                    <div class="task-header d-flex justify-content-between">
                      <span class="task-header-assigner">{{ task.assignedBy.fullName }}</span>
                      <span class="task-date">{{ formatDate(task.createdAt, 'MM/DD/YYYY hh:mm a') }}</span>
                    </div>
                    <span class="assigned-task">Assigned task</span>
                    <div class="task-body">
                      <div class="d-flex">
                        <div class="form-group me-2" @click="toggleTaskModal(note.task.id, 'subTask')">
                          <input :checked="task.status === 'done'" class="form-check-input" type="checkbox" disabled>
                        </div>
                        <div class="task-info">
                          <div class="task-info-name" @click="toggleTaskModal(note.task.id, 'subTask')">{{ task.title }}</div>
                          <div class="d-flex mt-1">
                            <div class="task-comments" @click="toggleTaskModal(note.task.id, 'Comments')">
                              <img alt="Comments icon" src="@/assets/icons/icon-comment-18.svg">
                              <span>{{ task.comments.length }}</span>
                            </div>
                            <div class="task-comments ml-3" @click="toggleTaskModal(note.task.id, 'Attachments')">
                              <img alt="Comments icon" src="@/assets/icons/icon-attachment-18.svg">
                              <span>{{ task.documetsTotal }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="tab === 'addTask'" class="add-new-task">
            <div class="modal-body-container mt-4 pb-4" @click="tab = 'Notes'">
              <div class="d-flex align-items-center">
                <img alt="" class="me-2" src="@/assets/icons/icon-arrow-left.svg">
                <div class="modal-body-container__title">Create new task</div>
              </div>
            </div>
            <div class="modal-body-container border-0">
              <div class="form-group col-md-6 mt-4 full-width">
                <label for="fieldAddTask">Task Name</label>
                <input id="fieldAddTask" v-model="formData.taskName" class="form-control full-width" type="text">
              </div>
              <div class="row mt-4">
                <div class="col-md-6">
                  <label for="fieldAssigner">Assignee</label>
                  <multiselect id="fieldAssigner"
                               v-model="formData.selectAssigner"
                               :allow-empty="false"
                               :close-on-select="true"
                               :options="formData.assigners"
                               :searchable="false"
                               :show-labels="false"
                               class="full-width w-100"
                               label="fullName"
                               placeholder="Select Assigner"
                               track-by="id">
                  </multiselect>
                </div>
                <div class="col-md-6">
                  <label for="fieldFiles">File</label>
                  <multiselect id="fieldFiles"
                               v-model="loan"
                               :disabled="true"
                               :options="[]"
                               class="full-width w-100"
                               label="borrowerName"
                               placeholder="Select Assigner"
                               track-by="id">
                  </multiselect>
                </div>
              </div>
              <div class="form-group full-width mt-4 submit">
                <button class="btn btn-primary w-100" @click="saveTask">
                  <span v-if="!ui.saveLoading">Create Task</span>
                  <span v-else aria-hidden="true" class="spinner-border spinner-border-sm" role="status"></span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import PerfectScrollbar from 'perfect-scrollbar'
import UwmNotes from './UwmNotes';

export default {
  name: "NotesModal",
  components: {Multiselect, UwmNotes},
  props: {
    loanId: Number
  },
  data() {
    return {
      tab: 'Notes',
      comment: "",
      tasksLoading: false,
      ui: {
        saveLoading: false,
        disableButton: false,
        ps: null
      },
      formData: {
        taskName: '',
        assigners: [],
        selectAssigner: null
      },
      loan: {},
      notes: [],
      tasks: [],
      attachments: []
    }
  },
  methods: {
    setTab(tabName) {
      this.tab = tabName;
    },
    updateModalData() {
      this.notes.filter(note => {
        if (note.type === 'task') {
          note.task.documetsTotal = 0
          note.task.comments.filter(comment => {
            let commentDocumentsCount = comment.documents.length
            if (commentDocumentsCount > 0) {
              note.task.documetsTotal += commentDocumentsCount
              this.attachments.push(comment)
            }
          })

          this.tasks.push(note.task)
        }
      })
    },
    tasksLoad() {
      this.tasksLoading = true;
      this.tasks = [];
      this.attachments = [];
      this.$http.get('/api/v1/loans/notes/' + this.loanId)
        .then((res) => {
          this.notes = res.data.notes
          this.loan = res.data.loan
          this.formData.assigners = res.data.agents
          this.updateModalData()
        })
        .finally(() => this.tasksLoading = false)
    },
    saveTask() {
      this.ui.saveLoading = true;
      this.$http
        .post('/api/v1/tasks/store', {
          title: this.formData.taskName,
          loanId: this.loan.id,
          agentId: this.formData.selectAssigner.id
        })
        .then(res => {
          this.tasks.unshift(res.data.task)
          this.tasksLoad()
          this.ui.saveLoading = !this.ui.saveLoading
          this.setTab('Notes')
        })
    },
    addComment: function () {
      this.ui.disableButton = true
      let formData = new FormData()
      formData.append('message', this.comment)
      this.$http
        .post('/api/v1/loans/notes/new/' + this.loanId, formData)
        .then(() => {
          this.ui.disableButton = false
          this.comment = '';
          this.tasksLoad();
        })
    },
    downloadDocument(id, filename) {
      this.$http
        .get(`/api/v1/task/comments/download/${id}`)
        .then(res => {
          let fileURL = window.URL.createObjectURL(new Blob([res.data]));
          let fileLink = document.createElement('a');
          fileLink.href = fileURL;
          fileLink.setAttribute('download', filename);
          document.body.appendChild(fileLink);
          fileLink.click();
        })
        .catch()
    },
  },
  created() {
    this.tasksLoad();
  },
  computed: {
    taskTodoCount() {
      return this.tasks.filter(task => {
        return task.status != 'done'
      }).length;
    },
  },
  mounted() {
    const scrollbar = document.getElementById('modal-body-scroll');
    this.ui.ps = new PerfectScrollbar(scrollbar);

    this.$root.$on('load-notes-tasks', () => {
      this.tasksLoad();
    })
  }
}
</script>

<style lang="scss" scoped>
#modal-body-scroll {
  position: relative;
  height: calc(100% - 50px);
}

.form-check-input {
  width: 18px;
  height: 18px;
  border-radius: 50% !important;
  margin-top: 0;

  &:checked {
    background-color: #0d6efd;
    border-color: #0d6efd;
  }
}

.app-updated {
  border: 1px solid rgba(239, 239, 239, 1);
  border-radius: 4px;
  padding: 10px 12px;
  margin-top: 5px;

  div {
    width: 33%;

    &:first-child,
    &:last-child {
      color: rgba(0, 0, 0, 1);
      font-size: 11px;
      letter-spacing: 0;
      line-height: 17px;
    }

    &:nth-child(2) {
      span {
        opacity: 0.46;
        color: rgba(0, 0, 0, 1);
        font-size: 11px;
        letter-spacing: 0;
        line-height: 17px;
      }
    }
  }
}

.note-click {
  cursor: pointer;
}

.attach-file {
  height: 36px;
  margin-top: 10px;

  img {
    width: 18px;
    height: 18px;
  }

  .file-name {
    color: rgba(0, 0, 0, 1);
    font-size: 12px;
    letter-spacing: 0;
    line-height: 18px;
  }
}

.note-body {
  padding: 0 12px 20px 12px;
  border-bottom: 1px solid rgba(231, 232, 232, 1);
}

.avatar {
  height: 36px;
  width: 36px;
  border: 1px solid rgba(237, 237, 238, 1);
  border-radius: 50%;
}

.task-header-assigner {
  color: rgba(0, 0, 0, 1);
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
}

.task-date {
  opacity: 0.9;
  color: rgba(0, 0, 0, 1);
  font-size: 12px;
  letter-spacing: 0;
  line-height: 16px;
  font-weight: 600;
}

.assigned-task {
  white-space: break-spaces;
  color: rgba(0, 0, 0, 1);
  font-size: 12px;
  font-style: italic;
  font-weight: 300;
  letter-spacing: 0;
}

.task-body {
  border: 1px solid rgba(239, 239, 239, 1);
  border-radius: 4px;
  padding: 9px;
  min-height: 60px;
  margin-top: 9px;
}

.task-info-name {
  color: rgba(0, 0, 0, 1);
  font-size: 13px;
  letter-spacing: 0;
  line-height: 20px;
}

.task-comments img {
  opacity: 0.46;
}

.task-comments span {
  color: rgba(138, 138, 138, 1);
  font-size: 11px;
  letter-spacing: 0;
  line-height: 11px;
}

.update-task {
  color: rgba(0, 0, 0, 1);
  font-size: 13px;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: 7px;
}

.bg-primary {
  height: 36px;
  max-width: 180px;
  width: 100%;
}

::v-deep {
  .multiselect {
    min-height: 44px;
    max-height: 44px;
    min-width: 180px;
    width: fit-content;
    background: none;
    @media screen and (max-width: 568px) {
      max-width: 50%;
      min-width: 100%;
    }

    &.multiselect--active {
      .multiselect__tags {
        transition: all .3s;
        border: 1px solid rgba(38, 111, 229, 0.5);
        box-shadow: 0 0 0 4px rgba(38, 111, 229, 0.1);
      }
    }

    &__tags {
      min-height: 44px;
      max-height: 44px;
      padding: 10px 40px 0 8px;
      transition: all .3s;

      &:hover {
        border: 1px solid rgba(38, 111, 229, 0.2);
        box-shadow: 0 0 0 4px rgba(38, 111, 229, 0.1);
      }
    }

    &__select {
      min-height: 44px;
      max-height: 44px;
      background: none;

      &:before {
        top: 15%;
        position: relative;
        right: 0;
        color: #999;
        border: solid rgba(0, 0, 0, 0.46);
        margin-top: 4px;
        border-width: 0px 1px 1px 0;
        display: inline-block;
        padding: 3px;
        content: "";
        transform: rotate(44deg);
      }
    }

    &__single {
      color: rgba(0, 0, 0, 0.46);
      @media screen and (max-width: 568px) {
        font-size: 9px;
      }
    }

    &__option {
      padding: 10px 12px;
      min-height: 36px;
      transition: all .3s;
      line-height: 20px;
      font-size: 14px;
      color: #000;
      @media screen and (max-width: 568px) {
        font-size: 9px;
      }

      &--highlight {
        background: #f3f3f3;
        line-height: 20px;
        font-size: 14px;
        color: #000;
        @media screen and (max-width: 568px) {
          font-size: 9px;
        }
      }
    }
  }
}

.notes-field {
  button {
    min-width: 220px;
    color: #fff;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 14px;

    background-color: #0d6efd !important;
  }
}
</style>
