<template>
  <div class="modal" id="taskreassign">
    <div class="modal-bg" @click="$emit('close')"></div>

    <div class="modal-block" v-if="loading === true">
      <div class="modal-body">
        <div class="modal-body-container border-0">
          <div class="text-center">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal-block" v-if="loading === false">
      <div class="modal-header d-block">
        <div class="d-flex">
          <h3>
            Reassign Task
          </h3>
        </div>
      </div>
      <div class="modal-body">
        <div class="modal-body-container mt-2">
          <ResponseError
            :status="error.status"
            :title="error.title"
            :details="error.details"
            :errors="error.errors"
          />
          <div class="modal-body-container_title">
            Task : {{ task.title }}
          </div>
          <div class="row">
            <div class="col-md-12">
              <label for="fieldAgent">
                Agents
              </label>
              <multiselect
                v-model="task.agent"
                class="full-width"
                id="fieldAgent"
                track-by="value"
                label="label"
                :options="formData.agents"
                :searchable="true"
                :close-on-select="true"
                :allow-empty="false"
              >
              </multiselect>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary w-25 me-auto" @click="reassignAgent" :disabled="formData.loading || task.agent === null">
          <span v-if="formData.loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          <span v-else>Reassign</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import ResponseError from "@/views/tasks/includes/ResponseError";

export default {
  name: "ReAssignee",
  components: {Multiselect, ResponseError},
  props: {
    taskId: Number
  },
  data() {
    return {
      loading: true,
      task: {
        agent: null,
        title: ''
      },
      formData: {
        loading: false,
        agents: []
      },
      error: {
        status: false,
        title: '',
        details: '',
        errors: []
      }
    }
  },
  methods: {
    loadTask() {
      this.loading = true
      this.$http.get(`/api/v1/tasks/reassign/form/${this.taskId}`)
        .then((res) => {
          this.task.title = res.data.title
          this.task.agent = res.data.agent
          this.formData.agents = res.data.agents
        })
        .catch((err) => {
          this.error.status = true
          if (err.response.data.errors) {
            this.error.title = err.response.data.message.trim()
            this.error.errors = err.response.data.errors
          } else if (err.response.data.status === 500) {
            this.hideError()
            this.$store.dispatch('OPEN_STATUS_NOTIFICATION', this.statusNotification = {
              msg: 'Internal Server Error.',
              status: 'error',
              delay: 2000
            });
          } else {
            this.error.title = err.response.data.title.trim()
            this.error.details = err.response.data.detail.trim()
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    reassignAgent() {
      this.formData.loading = true
      let formData = new FormData()
      formData.append('agentId', this.task.agent.value)
      this.$http.post(`/api/v1/tasks/reassign/assignee/${this.taskId}`, formData)
        .then(() => {
          this.closeModal()
          this.$store.dispatch('OPEN_STATUS_NOTIFICATION', this.statusNotification = {
            msg: 'Task Re-assign successfully!',
            status: 'success',
            delay: 2000
          });
        })
        .catch((err) => {
          if (err.response.data.status === 500) {
            this.$store.dispatch('OPEN_STATUS_NOTIFICATION', this.statusNotification = {
              msg: 'Internal Server Error.',
              status: 'error',
              delay: 2000
            });
          } else {
            this.error.status= true
            this.error.title = err.response.data.title.trim()
            this.error.details = err.response.data.detail.trim()
          }
        })
        .finally(() => {
          this.formData.loading = false
        })
    },
    hideError() {
      this.error = {
        status: false,
        title: '',
        details: '',
        errors: []
      }
    },
    closeModal() {
      this.$root.$emit('load-tasks')
      this.$emit('close')
    }
  },
  beforeMount() {
    this.loadTask()
  }
}
</script>
<style lang="scss" scoped>
#loanApp {
  .status {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 28px;
    padding: 6px 12px;
    &.pending {
      border: 1px solid #E7E8E8;
      border-radius: 8px;
      background-color: #F6F6F6;
      color: #000000;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 18px;
      margin-left: 12px;
    }
  }
}
</style>
