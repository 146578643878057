<template>
  <div class="modal modal-center">
    <div class="modal-bg" @click="$emit('close')"></div>
    <div class="modal-block">
      <div class="text-center my-5" v-if="ui.loading === true">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
      <div class="modal-body" v-if="ui.loading === false">
        <ResponseError
          :status="error.status"
          :title="error.title"
          :details="error.details"
          :errors="error.errors"
          @hide="hideError"
        />
        <div class="title">
          <button class="btn btn-control close-btn" @click="$emit('close')">
            <img src="@/assets/icons/icon-close.png" alt="">
          </button>
          <span>Task /</span> Edit
        </div>
        <div class="row mt-4">
          <div class="col-8">
            <div class="form-group full-width">
              <label for="fieldTaskName">
                Task Name
              </label>
              <input type="text" id="fieldTaskName"
                     v-model="fields.taskName"
                     class="form-control">
            </div>
          </div>
          <div class="col-4">
            <div class="form-group">
              <label for="fieldFile">
                Select File
              </label>
              <multiselect v-model="fields.file"
                           placeholder="Select File"
                           id="fieldFile"
                           track-by="value"
                           label="label"
                           :max-height="250"
                           :options="formData.loanFiles"
                           :searchable="true"
                           :close-on-select="true"
                           :allow-empty="false"
                           :show-labels="false">
              </multiselect>
            </div>
          </div>
        </div>
        <div class="form-group full-width mt-2">
          <label for="fieldDescription">Description (Optional)</label>
          <textarea name=""
                    cols="30"
                    rows="5"
                    id="fieldDescription"
                    class="form-control"
                    v-model="fields.description"
                    placeholder="Write a description">
          </textarea>
        </div>
        <div class="row mt-2">
          <div class="col-6">
            <div class="form-group">
              <label for="fieldAssignee">
                Task Assignee
              </label>
              <multiselect v-model="fields.assignee"
                           placeholder="Select Assignee"
                           id="fieldAssignee"
                           track-by="value"
                           label="label"
                           :max-height="250"
                           :options="formData.assignee"
                           :searchable="true"
                           :close-on-select="true"
                           :allow-empty="false"
                           :show-labels="false">
              </multiselect>
            </div>
          </div>
          <div class="col-6 text-end mt-auto">
            <button type="button"
                    class="btn btn-primary w-50"
                    @click="editTask"
                    :disabled="fields.taskName.trim() === '' ||
                    fields.file === null ||
                    fields.assignee === null ||
                    ui.form.loading">
              <span v-if="ui.form.loading"
                    class="spinner-border spinner-border-sm"
                    role="status" aria-hidden="true">
              </span>
              <span v-if="!ui.form.loading">Save</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Multiselect from 'vue-multiselect';
import ResponseError from "@/views/tasks/includes/ResponseError";

export default {
  name: "EditTask",
  components: { Multiselect, ResponseError },
  props: {
    taskId: Number
  },
  data() {
    return {
      fields: {
        taskName: '',
        file: null,
        assignee: null,
        description: null
      },
      formData: {
        loanFiles: [],
        assignee: []
      },
      ui: {
        loading: false,
        form: {
          loading: false
        }
      },
      error: {
        status: false,
        title: '',
        details: '',
        errors: []
      }
    }
  },
  methods: {
    loadingForm() {
      this.ui.loading = true
      this.$http.get(`/api/v1/tasks/form/edit/${this.taskId}`).then((res) => {
        this.ui.loading = false
        this.formData.loanFiles = res.data.loanFiles
        this.formData.assignee = res.data.assignee
        this.fields = res.data.fields
      })
    },
    hideError() {
      this.error = {
        status: false,
        title: '',
        details: '',
        errors: []
      }
    },
    editTask() {
      this.ui.form.loading = true
      let formData = new FormData()
      formData.append('title', this.fields.taskName)
      formData.append('loanId', this.fields.file ? this.fields.file.value : '')
      formData.append('agentId', this.fields.assignee ? this.fields.assignee.value : '')
      formData.append('description', this.fields.description)
      this.$http.post(`api/v1/tasks/edit/${this.taskId}`, formData)
       .then(() => {
          this.$store.dispatch('OPEN_STATUS_NOTIFICATION', this.statusNotification = {
            msg: 'Task updated successfully!',
            status: 'success',
            delay: 2000
          });
          this.$root.$emit('load-tasks')
          this.$emit('close')
      })
      .catch((err) => {
        this.error.status = true
        if (err.response.data.errors) {
          this.error.title = err.response.data.message.trim()
          this.error.errors = err.response.data.errors
        } else if (err.response.data.status === 500) {
          this.hideError()
          this.$store.dispatch('OPEN_STATUS_NOTIFICATION', this.statusNotification = {
            msg: 'Internal Server Error.',
            status: 'error',
            delay: 2000
          });
        } else {
          this.error.title = err.response.data.title.trim()
          this.error.details = err.response.data.detail.trim()
        }
      })
      .finally(() => {
        this.ui.form.loading = false
      })
    }
  },
  beforeMount() {
    this.loadingForm()
  }
}
</script>

<style lang="scss" scoped>
.modal.modal-center {
  .modal-block {
    height: auto;
    padding: 20px;
    margin-top: 10vh;
    overflow-y: auto;
    border-radius: 8px;
  }
  .modal-body {
    padding: 30px;
  }
  label {
    color: #000000;
    font-size: 11px;
    letter-spacing: 0;
    line-height: 11px;
    margin-bottom: .5rem;
  }
  .title {
    word-spacing: 10px;
    span {
      opacity: 0.46;
    }
  }
  .close-btn {
    float: right;
    &:hover {
      background-color: #FFF;
    }
  }
  input, textarea {
    background-color: #F3F3F4;
  }
}
</style>
