<template>
  <div>
    <div>
      <div class="page-header d-flex justify-content-between align-items-center">
        <div class="d-flex align-items-center">
          <h3 class="page-header-title">Tasks<span>
              You have completed {{ tasks.countToday }} task<span v-if="tasks.countToday > 1">s</span> today.
            </span>
          </h3>
        </div>
        <div class="page-header-buttons">
          <base-button title="Create a Task" action="primary" @click-btn="modals.createTask = true" />
        </div>
      </div>
      <div class="page-tabs d-flex justify-content-between">
        <ul class="tab-list">
          <li :class="{ active: isActive('assigned') }"
              @click="setActiveTab('assigned')">
            Assigned to Me <span class="tab-list-item--count">{{ tasks.assignedToMe }}</span>
          </li>
          <li :class="{ active: isActive('created') }"
              @click="setActiveTab('created')">
            Created by Me <span class="tab-list-item--count">{{ tasks.createdByMe }}</span>
          </li>
          <li :class="{ active: isActive('completed') }"
              @click="setActiveTab('completed')">
            Completed <span class="tab-list-item--count">{{ tasks.completed }}</span>
          </li>
        </ul>
      </div>
      <div class="text-center mt-5" v-if="ui.loading">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>

      <transition name="component-fade" mode="out-in" v-else>
        <component v-bind:is="activeTab" :count="tasks" @load-tasks="getTasks"></component>
      </transition>
    </div>
    <transition name="component-fade" mode="out-in">
      <CreateTask @close="modals.createTask = false" @load-tasks="getTasks" v-if="modals.createTask"/>
    </transition>
  </div>
</template>

<script>
import BaseButton from '../../components/BaseButton.vue';

import Assigned from "./includes/Assigned";
import Completed from "./includes/Completed";
import Created from "./includes/Created";
import CreateTask from "./modals/CreateTask";

export default {
  name: "Tasks",
  components: {Assigned, Completed, Created, CreateTask, BaseButton},
  data() {
    return {
      activeTab: 'assigned',
      ui: {
        loading: true
      },
      tasks: {
        countToday: 0,
        completed: 0,
        createdByMe: 0,
        assignedToMe: 0
      },
      modals: {
        createTask: false
      }
    }
  },
  methods: {
    setActiveTab(tabName) {
      this.activeTab = tabName
    },
    getTasks() {
      this.ui.loading = true
      this.$http.get('/api/v1/tasks/stats').then((res) => {
        this.ui.loading = false
        this.tasks = res.data
      })
    },
    isActive(tabName) {
      return this.activeTab === tabName
    }
  },
  beforeMount() {
    this.getTasks()
    if (this.$route.query.task) {
      this.taskId = parseInt(this.$route.query.task);
      this.toggleTaskModal(this.taskId, 'subTask')
    }
  },
  mounted: function () {
    this.$root.$on('load-tasks', () => {
      this.getTasks()
    })
  }
}
</script>

<style lang="scss" scoped>
.page-header-title{
  & > span{
    color: #838384;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 12px;
    margin-left: 0.5rem;
    font-weight: normal;
  }
}

.component-fade-enter-active, .component-fade-leave-active {
  transition: opacity .3s ease;
}
.component-fade-enter, .component-fade-leave-to {
  opacity: 0;
}
</style>
