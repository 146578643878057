<template>
  <div class="modal " id="loanreassign">
    <div class="modal-bg" @click="$emit('close')"></div>

    <div class="modal-block" v-if="loading === true">
      <div class="modal-body">
        <div class="modal-body-container border-0">
          <div class="text-center mt-5">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal-block" v-if="loading === false">
      <div class="modal-header d-block">
        <div class="d-flex">
          <h3>
            Reassign Loan
          </h3>
        </div>
      </div>
      <div class="modal-body">
        <div class="modal-body-container mb-4 pb-3">
          <div class="d-flex align-items-center">
            <v-avatar :username="loan.borrower" rounded />
            <h6 class="ml-3 mb-0">
                {{ loan.borrower }}
            </h6>
          </div>
        </div>
        <div class="modal-body-container mt-2 mb-0 border-0">
          <div class="row">
            <div class="col-md-12">
              <label for="fieldAgent">
                Agents
              </label>
              <multiselect
                  v-model="loan.agent"
                  class="full-width"
                  id="fieldAgent"
                  track-by="key"
                  label="value"
                  :options="formData.agents"
                  :selectLabel="''"
                  :selectGroupLabel="''"
                  :deselectLabel="''"
                  :deselectGroupLabel="''"
                  :searchable="true"
                  :close-on-select="true"
                  :allow-empty="false"
              >
              </multiselect>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-md-6">
              <button type="button" class="btn btn-primary w-75" @click="reassignAgent">
                <span v-if="formData.loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                <span v-if="!formData.loading">Reassign</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'

export default {
  name: "LoanReassignModal",
  components: {Multiselect},
  props: {
    loanId: Number
  },
  data() {
    return {
      loading: true,
      loan: {
        agent: null,
        borrower: ''
      },
      response: {
        message: ''
      },
      formData: {
        isSuccess: false,
        isError: false,
        loading: false,
        agents: []
      }
    }
  },
  methods: {
    loadLoan() {
      this.loading = true
      this.$http.get(`/api/v1/loans/agent/byloanid/${this.loanId}`)
        .then((res) => {
          this.loading = false
          this.loan.borrower = res.data.borrower
          this.formData.agents = res.data.agents
          this.loan.agent = res.data.agent
        })
    },
    reassignAgent() {
      this.formData.loading = true
      let formData = new FormData()
      formData.append('agent', this.loan.agent.key)
      this.$http.post(`/api/v1/loans/reassign/${this.loanId}`, formData)
        .then((res) => {
          this.formData.loading = false
          this.response.message = res.data.message
          if (res.data.status === 'success') {
            this.$store.dispatch('OPEN_STATUS_NOTIFICATION', this.statusNotification = {
              msg: this.response.message,
              status: 'success',
              delay: 2000
            });
            setTimeout(() => {
              this.$emit('load-loans')
              this.$emit('close')
            }, 1500)
          } else {
            this.$store.dispatch('OPEN_STATUS_NOTIFICATION', this.statusNotification = {
              msg: this.response.message,
              status: 'error',
              delay: 2000
            });
          }
        })
        .catch((err) => {
          this.$store.dispatch('OPEN_STATUS_NOTIFICATION', this.statusNotification = {
            msg: err.response.data.erro,
            status: 'error',
            delay: 2000
          });
      })
    }
  },
  created() {
    this.loadLoan()
  }
}
</script>
<style lang="scss" scoped>
#loanApp {
  .status {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 28px;
    padding: 6px 12px;
    &.pending {
      border: 1px solid #E7E8E8;
      border-radius: 8px;
      background-color: #F6F6F6;
      color: #000000;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 18px;
      margin-left: 12px;
    }
  }
}
#loanreassign {
  .modal-block {
    min-height: 500px;
    min-width: 400px;
  }
}
.modal-body-container::v-deep .multiselect {
  &.selected {
    .multiselect__tags {
      background-color: #F3F3F4;
    }

    .multiselect__single {
      background-color: #F3F3F4;
      color: #000000;
      font-size: 14px;
      letter-spacing: 0;
    }

    input {
      background-color: #F3F3F4;
    }
  }

  &.multiselect--active {
    .multiselect__tags {
      transition: all .3s;
      border: 1px solid rgba(38, 111, 229, 0.5);
      box-shadow: 0 0 0 4px rgba(38, 111, 229, 0.1);
    }
  }

  &__tags {
    padding: 12px 40px 0 15px;
    transition: all .3s;

    &:hover {
      border: 1px solid rgba(38, 111, 229, 0.2);
      box-shadow: 0 0 0 4px rgba(38, 111, 229, 0.1);
    }
  }

  &__select {
    &:before {
      top: 17%;
      position: relative;
      right: 0;
      color: #999;
      border: solid rgba(0, 0, 0, 0.46);
      margin-top: 4px;
      border-width: 0px 1px 1px 0;
      display: inline-block;
      padding: 3px;
      content: "";
      transform: rotate(44deg);
    }
  }

  &__single {
    color: rgba(0, 0, 0, 0.46);
    margin-bottom: 0;
    @media screen and (max-width: 568px) {
      font-size: 9px;
    }
  }

  &__option {
    padding: 10px 12px;
    min-height: 36px;
    transition: all .3s;
    line-height: 20px;
    font-size: 14px;
    color: #000;
    @media screen and (max-width: 568px) {
      font-size: 9px;
    }

    &--highlight {
      background: #f3f3f3;
      line-height: 20px;
      font-size: 14px;
      color: #000;
      @media screen and (max-width: 568px) {
        font-size: 9px;
      }
    }
  }

  &__placeholder {
    padding: 0;
  }
}

</style>
