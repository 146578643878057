<template>
  <div>
    <div class="page-content text-center mt-5" v-show="ui.loading">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <div class="page-content p-normal" v-show="!ui.loading">
      <div class="d-flex justify-content-between">
        <div class="search-wrapper">
          <img src="@/assets/icons/icon-search.svg" alt="Icon search">
          <input type="text" placeholder="Search" v-model="filter.query" aria-label="Search">
        </div>
        <div class="d-flex table-filter">
          <div class="form-group full-width">
            <multiselect v-model="filter.loanFile"
                         track-by="id"
                         label="borrowerName"
                         id="fieldTaskFile"
                         class="full-width"
                         open-direction="bottom"
                         :options="filter.loanFiles"
                         :searchable="true"
                         :close-on-select="true"
                         :show-labels="false"
                         placeholder="Select Loan File">
            </multiselect>
          </div>
        </div>
      </div>
      <table class="table table-striped">
        <thead>
          <tr>
            <th colspan="2">Task</th>
            <th>Loan File</th>
            <th>Assigned by</th>
            <th>Created</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(task, index) in tableSearch" :key="index">
            <td>
              <div class="form-group form-check-round mt-4">
                <input type="checkbox"
                       class="form-check-input"
                       @click="markAsCompleted(task.id)"
                       aria-label="mark as completed"
                       content="Mark task as completed"
                       v-tippy="{arrow: true, placement: 'right-center'}">
              </div>
            </td>
            <td>
              <div class="task">
                <div class="task-title cursor-pointer" @click="toggleTaskModal(task.id, 'subTask')">
                  {{ task.title }}
                </div>
                <div class="d-flex mt-2">
                  <div class="me-3 cursor-pointer" @click="toggleTaskModal(task.id, 'subTask')">
                    <img src="@/assets/icons/icon-sub-task-18.svg" alt="Sub task">
                    {{ task.subtasks.length }}
                  </div>
                  <div class="me-3 cursor-pointer" @click="toggleTaskModal(task.id, 'Comments')">
                    <img src="@/assets/icons/icon-comment-18.svg" alt="Comments">
                    {{ task.comments.length }}
                  </div>
                  <div class="cursor-pointer" @click="toggleTaskModal(task.id, 'Attachments')">
                    <img src="@/assets/icons/icon-attachment-18.svg" alt="Attachments">
                    {{ getTotalAttachments(task.comments) }}
                  </div>
                </div>
              </div>
            </td>
            <td>
              <div class="loan-file" v-if="task.loan">
                {{ task.loan.borrowerName }}
                <p class="mt-1" @click="openLoanFile(task.loan.id)">View File</p>
              </div>
              <div class="loan-file" v-else>
                No Loan File
              </div>
            </td>
            <td>
              <div class="assigned-by">
                {{ task.assignedTo.fullName }}
                <p class="mt-1">{{ task.assignedTo.position }}</p>
              </div>
            </td>
            <td>
              {{ task.createdAt | formatDate }}
            </td>
            <td>
              <button :name="'taskAction' + task.id" class="btn btn-control" parent>
                <img src="@/assets/icons/icon-more.svg" alt="">
              </button>
              <tippy :to="'taskAction' + task.id"
                     trigger="click"
                     arrowType="round"
                     boundary="window"
                     interactive="true"
                     animateFill="false"
                     placement="bottom-end"
                     zIndex="99" hideOnClick="true" theme="light"
                     sticky="true" class="task-action">
                <div class="task-action-block">
                  <ul class="mb-0">
                    <li>
                      <a href="#" @click="taskId=task.id, modals.reAssignee=true">
                        <img src="@/assets/icons/icon-login.svg" alt="">
                        Change Assignee
                      </a>
                    </li>
                    <li>
                      <a href="#" @click="assignToMySelf(task.id)">
                        <img src="@/assets/icons/icon-profile.svg" alt="">
                        Assign to myself
                      </a>
                    </li>
                    <li class="action-label">Actions</li>
                    <li>
                      <a href="#" @click="taskId=task.id, modals.edit=true">
                        <img src="@/assets/icons/icon-edit-normal.svg" alt="">
                        Edit Task
                      </a>
                    </li>
                    <li>
                      <a href="#" @click="deleteTask(task.id)">
                        <img src="@/assets/icons/icon-archive.svg" alt="">
                        Delete Task
                      </a>
                    </li>
                  </ul>
                </div>
              </tippy>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="col-12 modal-alert modal-alert_advice text-center" v-if="tableSearch.length === 0">
        No Results Found
      </div>
    </div>
    <transition name="component-fade" mode="out-in">
      <EditTask v-if="modals.edit" @close="modals.edit = false" :taskId="taskId"/>
    </transition>
    <transition name="component-fade" mode="out-in">
      <LoanFile v-if="modals.loanFile" @close="modals.loanFile = false" :loanId="loanId"/>
    </transition>
    <transition name="component-fade" mode="out-in">
      <ReAssignee v-if="modals.reAssignee" @close="modals.reAssignee = false" :taskId="taskId"/>
    </transition>
  </div>
</template>

<script>
import moment from "moment";
import Multiselect from 'vue-multiselect';
import EditTask from '@/views/tasks/modals/EditTask';
import LoanFile from "@/views/tasks/modals/LoanFile";
import ReAssignee from "@/views/tasks/modals/ReAssignee";

export default {
  name: "Assigned",
  props: {
    count: Object
  },
  components: {Multiselect, EditTask, LoanFile, ReAssignee},
  data() {
    return {
      ui: {
        loading: true
      },
      modals: {
        loanFile: false,
        edit: false,
        reAssignee: false,
        taskDetails: false
      },
      filter: {
        query: '',
        loanFile: null,
        loanFiles: [],
      },
      tasks: [],
      loanId: null,
      taskId: null
    }
  },
  methods: {
    getTotalAttachments(comments) {
      let total = 0;

      comments.filter(comment => {
        total += comment.documents.length
      })

      return total
    },
    getTasks() {
      this.ui.loading = true
      if (this.count.assignedToMe > 0) {
        this.$http
        .get('/api/v1/tasks/assignee')
        .then((res) => {
          this.ui.loading = false
          this.tasks = res.data.tasks
          this.tasks.filter(task => {
            if (task.loan) {
              this.filter.loanFiles.push(task.loan)
            }
          })
          this.filter.loanFiles = this.getUniqueArray(this.filter.loanFiles, 'id')
        })
      } else {
        this.ui.loading = false
      }
    },
    getUniqueArray(array, key){
      return [...new Map(array.map(item => [item[key], item])).values()]
    },
    deleteTask(id) {
      if(confirm("Are you sure?")){
        this.$http
          .delete(`/api/v1/tasks/delete/${id}`)
          .then(() => {
            this.$emit('load-tasks')
            this.$store.dispatch('OPEN_STATUS_NOTIFICATION', this.statusNotification = {
              msg: 'Task deleted successfully!',
              status: 'success',
              delay: 2000
            });
          })
          .catch((err) => {
            if (err.response.data.status === 500) {
              this.$store.dispatch('OPEN_STATUS_NOTIFICATION', this.statusNotification = {
                msg: 'Internal Server Error.',
                status: 'error',
                delay: 2000
              });
            } else {
              this.$store.dispatch('OPEN_STATUS_NOTIFICATION', this.statusNotification = {
                msg: err.response.data.detail.trim(),
                status: 'error',
                delay: 2000
              });
            }
          })
          .finally(() => {
            this.$emit('load-tasks')
          })
      }
    },
    assignToMySelf(id) {
      this.$http
        .get(`/api/v1/tasks/reassign/self/${id}`)
        .then(() => {
          this.$store.dispatch('OPEN_STATUS_NOTIFICATION', this.statusNotification = {
            msg: 'Successfully Assigned',
            status: 'success',
            delay: 2000
          });
        })
        .catch((err) => {
          if (err.response.data.status === 500) {
            this.$store.dispatch('OPEN_STATUS_NOTIFICATION', this.statusNotification = {
              msg: 'Internal Server Error.',
              status: 'error',
              delay: 2000
            });
          } else {
            this.$store.dispatch('OPEN_STATUS_NOTIFICATION', this.statusNotification = {
              msg: err.response.data.detail.trim(),
              status: 'error',
              delay: 2000
            });
          }
        })
        .finally(() => {
          this.$emit('load-tasks')
        })
    },
    markAsCompleted(taskId) {
      this.$http
        .get(`/api/v1/tasks/mark/completed/${taskId}`)
        .then(() => {})
        .catch((err) => {
          if (err.response.data.status === 500) {
            this.$store.dispatch('OPEN_STATUS_NOTIFICATION', this.statusNotification = {
              msg: 'Internal Server Error.',
              status: 'error',
              delay: 2000
            });
          } else {
            this.$store.dispatch('OPEN_STATUS_NOTIFICATION', this.statusNotification = {
              msg: err.response.data.detail.trim(),
              status: 'error',
              delay: 2000
            });
          }
        })
        .finally(() => {
          this.$emit('load-tasks')
        })
    },
    openLoanFile(id) {
      this.loanId = id
      this.modals.loanFile = true
    }
  },
  filters: {
    formatDate: function (value) {
      if (value) {
        return moment(String(value)).format('MMM D, Y, h:mm a')
      }
    }
  },
  computed: {
    tableSearch() {
      return this.tasks.filter(task => {
        if (this.filter.loanFile) {
          return task.title.toLowerCase().includes(this.filter.query.toLowerCase())
            && task.loan.id === this.filter.loanFile.id
        } else {
          return task.title.toLowerCase().includes(this.filter.query.toLowerCase())
        }
      })
    }
  },
  beforeMount() {
    this.getTasks()
  }
}
</script>

<style lang="scss" scoped>
table {
  tbody {
    tr {
      td:first-child {
        width: 1%;
        input {
          cursor: pointer;
        }
      }
    }
  }
}
.task {
  padding: 0 24px;
  transition: all .3s;
  max-width: max-content;
  .task-title {
    color: #000;
    font-size: 15px;
    letter-spacing: 0;
    line-height: 24px;
  }
  .d-flex {
    opacity: 0.7;
  }
}
.loan-file{
  color: #000;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 20px;
  p {
    cursor: pointer;
    color: #407cff;
    &:hover {
      color: #2167ff;
    }
  }
}
.assigned-by{
  color: #000;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 20px;
  p {
    opacity: .46;
    color: #000;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 18px;
  }
}

.task-action-block {
  background: white!important;
  ul {
    padding: 12px 0;
    list-style: none;
    li {
      text-align: initial;
      padding: 4px 11px;
      &:hover {
        background-color: rgba(0,0,0,.05);
      }
      a {
        color: rgba(0,0,0,1);
        font-size: 12px;
        letter-spacing: 0;
        line-height: 18px;
        text-decoration: none;
        img {
          opacity: 0.46;
        }
      }
    }
  }
  .action-label {
    opacity: 0.5;
    color: rgba(0,0,0,1);
    font-size: 11px;
    letter-spacing: 0;
    line-height: 17px;
    margin-top: 8px;
    margin-bottom: 8px;
  }
}
.table-filter::v-deep .multiselect {
  min-height: 36px;
  max-height: 36px;
  min-width: 180px;
  max-width: 180px;
  width: fit-content;
  @media screen and (max-width: 568px) {
    max-width: 50%;
    min-width: 100%;
  }
  &.multiselect--active {
    .multiselect__tags {
      transition: all .3s;
      border: 1px solid rgba(38,111,229,0.5);
      box-shadow: 0 0 0 4px rgba(38,111,229,0.1);
    }
  }
  &__tags {
    min-height: 36px;
    max-height: 36px;
    padding: 5px 40px 0 8px;
    transition: all .3s;
    &:hover {
      border: 1px solid rgba(38,111,229,0.2);
      box-shadow: 0 0 0 4px rgba(38,111,229,0.1);
    }
  }
  &__select {
    min-height: 36px;
    max-height: 36px;
    &:before {
      top: 10%;
      position: relative;
      right: 0;
      color: #999;
      border: solid rgba(0, 0, 0, 0.46);
      margin-top: 4px;
      border-width: 0px 1px 1px 0;
      display: inline-block;
      padding: 3px;
      content: "";
      transform: rotate(44deg);
    }
  }
  &__single {
    color: rgba(0, 0, 0, 0.46);
    @media screen and (max-width: 568px) {
      font-size: 9px;
    }
  }
  &__option {
    padding: 10px 12px;
    min-height: 36px;
    transition: all .3s;
    line-height: 20px;
    font-size: 14px;
    color: #000;
    @media screen and (max-width: 568px) {
      font-size: 9px;
    }
    &--highlight {
      background: #f3f3f3;
      line-height: 20px;
      font-size: 14px;
      color: #000;
      @media screen and (max-width: 568px) {
        font-size: 9px;
      }
    }
  }
}
</style>
