<template>
  <div class="modal modal-center">
    <div class="modal-bg" @click="$emit('close')"></div>
    <div class="modal-block">
      <div class="modal-header">
        <h3>Create New Task</h3>
      </div>
      <div class="text-center mt-5 pt-5 pb-5" v-if="ui.loading === true">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
      <div class="modal-body" v-if="ui.loading === false">
        <ResponseError
          :status="error.status"
          :title="error.title"
          :details="error.details"
          :errors="error.errors"
          @hide="hideError"
        />
        <div class="form-group full-width">
          <label for="fieldTaskName">Task Name</label>
          <input type="text" id="fieldTaskName" v-model="fields.taskName" class="form-control" autocomplete="off">
        </div>
        <div class="row mt-3">
          <div class="form-group col-md-6 mb-3">
            <label for="fieldFile">Select File</label>
            <multiselect v-model="fields.file"
                         placeholder="Select File"
                         id="fieldFile"
                         track-by="value"
                         label="label"
                         :max-height="250"
                         :options="formData.loanFiles"
                         :searchable="true"
                         :close-on-select="true"
                         :allow-empty="false"
                         :show-labels="false">
            </multiselect>
          </div>
          <div class="form-group col-md-6 mb-3">
            <label for="fieldAssignee">Task Assignee</label>
            <multiselect v-model="fields.assignee"
                         placeholder="Select Assignee"
                         id="fieldAssignee"
                         track-by="value"
                         label="label"
                         :max-height="250"
                         :options="formData.assignee"
                         :searchable="true"
                         :close-on-select="true"
                         :allow-empty="false"
                         :show-labels="false">
            </multiselect>
          </div>
        </div>
      </div>
      <div class="modal-footer d-flex justify-content-start" v-if="!ui.loading">
        <base-button title="Create Task" action="secondary-default" @click-btn="createTask" :loading="ui.form.loading"
                     :disabled="ui.form.loading ||
                     fields.taskName.trim() === '' ||
                     fields.file === null ||
                     fields.assignee === null" />
        <base-button title="Discard" type="reset" action="secondary" @click-btn="$emit('close')" class="ms-2" />
      </div>
    </div>
  </div>
</template>

<script>

import Multiselect from 'vue-multiselect';
import ResponseError from "@/views/tasks/includes/ResponseError";
import BaseButton from '../../../components/BaseButton.vue';

export default {
  name: "CreateTask",
  components: { Multiselect, ResponseError, BaseButton },
  props: {
    selectedLoan: {
      type: Object
    }
  },
  data() {
    return {
      fields: {
        taskName: '',
        file: null,
        assignee: null
      },
      formData: {
        loanFiles: [],
        assignee: []
      },
      ui: {
        loading: false,
        form: {
          loading: false
        }
      },
      error: {
        status: false,
        title: '',
        details: '',
        errors: []
      }
    }
  },
  methods: {
    loadingForm() {
      this.ui.loading = true
      this.$http.get(`/api/v1/tasks/create`).then((res) => {
        this.ui.loading = false
        this.formData.loanFiles = res.data.loanFiles

        if (this.selectedLoan) {
          this.fields.file = this.formData.loanFiles.find((el) => {
            return el.value === this.selectedLoan.id ? {label: el.fullName, value: el.id} : null
          })
        }
        this.formData.assignee = res.data.assignee
      })
    },
    createTask() {
      this.ui.form.loading = true
      this.$http.post(`api/v1/tasks/store`, {
        title: this.fields.taskName,
        agentId: this.fields.assignee ? this.fields.assignee.value : null,
        loanId: this.fields.file ? this.fields.file.value : null
      })
      .then((res) => {
        this.closeModal(res)
        this.$store.dispatch('OPEN_STATUS_NOTIFICATION', this.statusNotification = {
          msg: res.data.message,
          status: 'success',
          delay: 2000
        });
      })
      .catch((err) => {
        this.error.status = true
        if (err.response.data.errors) {
          this.error.title = err.response.data.message.trim()
          this.error.errors = err.response.data.errors
        } else if (err.response.data.status === 500) {
          this.hideError()
          this.$store.dispatch('OPEN_STATUS_NOTIFICATION', this.statusNotification = {
            msg: 'Internal Server Error.',
            status: 'error',
            delay: 2000
          });
        } else {
          this.error.title = err.response.data.title.trim()
          this.error.details = err.response.data.detail.trim()
        }
      })
      .finally(() => {
        this.ui.form.loading = false
      })
    },
    hideError() {
      this.error = {
        status: false,
        title: '',
        details: '',
        errors: []
      }
    },
    closeModal(res) {
      this.$emit('load-tasks', res)
      this.$emit('close')
    }
  },
  beforeMount() {
    this.loadingForm()
  }
}
</script>

<style lang="scss" scoped>
.modal.modal-center {
  .modal-block {
    height: fit-content;
    margin-top: 20vh;
    overflow-y: auto;
    border-radius: 8px 8px 0 0;
  }
  .modal-footer {
    bottom: unset;
  }
  label {
    margin-bottom: .3rem;
  }
  input, textarea {
    background-color: #F3F3F4;
  }
}
</style>
